




























import { Component, Vue } from 'vue-property-decorator'
import { UserInfo } from '@/types/developer'
@Component
export default class Developer extends Vue {
  private loading = false
  private tableData: UserInfo[] = []
  private page = 1
  private size = 10
  private total = 0

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<UserInfo> }>(this.$apis.developer.selectApiUserByPage, {
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.total = res.total || 0
        this.tableData = res.list || []
      })
      .finally(() => {
        this.loading = false
      })
  }

  onSwitch (id: string) {
    this.$axios.post(this.$apis.developer.deleteApiUser, {
      userId: id
    })
      .then(() => {
        this.getData()
        this.$message.success('操作成功')
      })
  }
}
